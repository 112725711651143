import "../styles/custom.scss";

import { Button, Form } from "react-bootstrap";

import React from "react";
import api from "../modules/api";
import { navigate } from "gatsby";

export default function ContactForm({ hideEmail, alt, className }) {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);

  function handleClear(e) {
    e.preventDefault();
    setEmail("");
    setName("");
    setMessage("");
  }

  function handleSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    const body = {
      form_name: "contact",
      email,
      name,
      message,
    };

    return api
      .contact(body)
      .then(() => navigate("/thanks"))
      .catch((error) => {
        console.error(error);
        setError(
          "Sorry, something went wrong. Please email hello@webhookdb.com to get in touch."
        );
      })
      .finally(() => setDisabled(false));
  }

  return (
    <div id="contact" className={className}>
      <div>
        {!hideEmail && (
          <p>
            Email us at <a href="mailto:hello@webhookdb.com">hello@webhookdb.com</a> or
            use this form:
          </p>
        )}
        <Form as="form" name="contact" onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className={alt ? "text-light" : "text-muted"}>
              We&rsquo;ll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              type="textarea"
              name="message"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
          {error && (
            <div>
              <p>{error}</p>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button
              variant={alt ? "outline-light" : "outline-primary"}
              type="reset"
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              className="ml-3 px-5"
              variant={alt ? "light" : "primary"}
              type="submit"
              disabled={disabled}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
