import ContactForm from "./ContactForm";
import Hilite from "./Hilite";
import Lead from "./Lead";
import { Link } from "gatsby";
import RLink from "./RLink";
import React from "react";
import clsx from "clsx";
import staticData from "./staticData";

export default function CtaAction({ alt, className, formClassName }) {
  const linkCls = alt ? "alt-link" : "";
  return (
    <div className={className}>
      <Lead>
        If you&rsquo;re interested in taking WebhookDB for a spin using our publicly
        available integrations, head on over to the{" "}
        <Link to="/terminal" className={linkCls}>
          terminal
        </Link>{" "}
        and <Hilite>get started from your browser.</Hilite> You can upgrade to a paid
        plan, or{" "}
        <RLink to={staticData.selfHosting} className={linkCls}>
          switch to self-hosting
        </RLink>
        , later.
      </Lead>
      <Lead>
        To get in touch, use this contact form, or email{" "}
        <a href="mailto:hello@webhookdb.com" className={linkCls}>
          hello@webhookdb.com
        </a>
        .
      </Lead>
      <ContactForm
        hideEmail
        alt={alt}
        className={clsx("max-width-sm", formClassName)}
      />
    </div>
  );
}
